import { useState, useMemo, memo, useEffect } from 'react';
import { Select } from 'antd';

const { Option } = Select;

const empty_arr = []
const SelectClusterInfo = memo(({ options = empty_arr, disabled = false,onClusterInfoChange, clusterName }) => {
    const [searchValue, setSearchValue] = useState('');
    const [allOptions, setAllOptions] = useState(options)
    const [value, setValue] = useState('')

    useEffect(()=>{
        setValue(clusterName);
    },[clusterName])

    const handleSearch = value => {
        setSearchValue(value);
    };

    const handleSelectChange = changedValue => {
        if (changedValue === "add") {
            setValue(changedValue)
            setAllOptions([...allOptions, searchValue])
            // onChange?.(searchValue)
            onClusterInfoChange(changedValue)
            return
        }
        setValue(changedValue);
        onClusterInfoChange(changedValue)
        // onChange?.(changedValue)
    };

    const filteredOptions = useMemo(() => {
        return allOptions.filter(opt =>
            opt.toLowerCase().includes(searchValue.toLowerCase())
        );
    }, [searchValue, allOptions])

    const renderOptions = useMemo(() => {
        return filteredOptions.map(opt => (
            <Option key={opt} value={opt}>
                {opt}
            </Option>
        ));
    }, [filteredOptions])

    if (searchValue && !filteredOptions.includes(searchValue)) {
        renderOptions.push(
            <Option key="add" value="add">
                {searchValue} (Click to add item)
            </Option>
        );
    }

    return (
        <Select
            showSearch
            disabled={disabled}
            value={value}
            onFocus={() => {
                //onChange?.(null)
                setSearchValue('')
            }}
            style={{
                minWidth: '10vw'
            }}
            allowClear
            placeholder="Select or type to search"
            defaultActiveFirstOption={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleSelectChange}
            notFoundContent={null}
        >
            {renderOptions}
        </Select>
    );
})

export default SelectClusterInfo;
