import * as auth from 'aws-amplify/auth';
import { useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react-core";
import { notification } from "antd";


const Authenticator = ({ children }) => {
    const { authStatus } = useAuthenticator()
    useEffect(() => {

        (async () => {
            try {
                if (authStatus !== 'authenticated') {
                    await auth.signInWithRedirect()
                }
            } catch (e) {
                if(e instanceof auth.AuthError){
                    if(e.name === "UserAlreadyAuthenticatedException"){
                        return
                    }
                }
                notification.error({message: 'Error signing in, contact app admin ...'})
                console.error(e)
            }
        })()


    }, [authStatus])
    return <>{authStatus === 'authenticated' ? children : []}</>
}

export default Authenticator