import { notification } from "antd";
import { getProcurementDetails, updateProcurementStageItem } from "apis";
import { create } from "zustand";
import {
  PROCUREMENT_STAGES_KEY,
} from "../types";


const procurementStore = (set, get) => ({
  procurementDetails: [],
  procurement_header_data: {},
  isDataUpdated: false,
  demandIntakeData: {}, /////focus on this......
  notes: [],
  current_stage_data: {},

  fetchProcurementData: async (PK, stageName) => {
    let result = await getProcurementDetails(PK);
    if (result.success) {
      console.log("demandIntakeDataObject", result.data);
      let current_stage_data_db = result?.data?.find(
        (item) => item.SK === PROCUREMENT_STAGES_KEY[stageName]
      );
      console.log("current_stage_data_db", current_stage_data_db);
      let demandIntakeDataObject = result?.data?.find(
        (item) => item.SK === PROCUREMENT_STAGES_KEY.Sizing
      );
      set({
        procurementDetails: result.data,
        procurement_header_data: result.data.find(
          (item) => item.PK === item.SK
        ),
        demandIntakeData: demandIntakeDataObject,
        current_stage_data : current_stage_data_db,
        notes : current_stage_data_db?.notes || []
      });
    } else if (result.error) {
      notification.error({
        message:
          "Failed to retrieve Procurement info. Please try again or contact admin.",
      });
    }
  },

  setIsDataUpdated: (value) => set({ isDataUpdated: value }),
  //to update notes and current state data in compoenent like usestate
  setNotesAndStageData : (updated_current_stage_data)=> set(()=>({
    current_stage_data : updated_current_stage_data,
    notes: updated_current_stage_data.notes
  })),

  updateStageData : async(updatedData) =>{
    let res = await updateProcurementStageItem(updatedData);
    if (res.success) {
      notification.success({
        message: "Update successful !",
      });
    } else {
      notification.error({
        message: "Update failed !",
        description: res.error?.toString(),
        duration: 0,
      });
    }
  }
  
});

const useProcurementStore = create(procurementStore);

export default useProcurementStore;
