import { Breadcrumb, Col, Descriptions, Row, Space, Typography } from "antd";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useProcurementStore from "store/procurement_store";

const ProcurementDetailsHeader = () => {
  const { procurement_header_data } = useProcurementStore((state)=>({
    procurement_header_data: state.procurement_header_data
  }));

  const navigate = useNavigate();
  const handleBreadcrumbClick = (e, path) => {
    e?.preventDefault();
    navigate(path);
  };

  const [searchParams] = useSearchParams();
  const stage_name = searchParams.get("stage");

  return (
    <Row gutter={[0, 20]}>
      <Col span={18}>
        <Space>
          <Breadcrumb
            items={[
              {
                title: "All Procurements",
                onClick: (e) => handleBreadcrumbClick(e, "/"),
                href: "/",
              },
              {
                title: procurement_header_data["procurement_name"]+" / "+`${stage_name}`,
              },
            ]}
          />
        </Space>
      </Col>

      <Row>
        <Col span={4} style={{ height: "60px" }} />
      </Row>
      <Col span={24}>
        <div
          id="overview-table-section"
          style={{ border: "3px solid #f6f6f6", borderRadius: "10px" }}
        >
          <Descriptions bordered size="small" column={1} layout="horizontal">
            <Descriptions.Item label="Procurement Name">
              {procurement_header_data["procurement_name"]}
            </Descriptions.Item>
            <Descriptions.Item label="Region">
              {procurement_header_data["region"]}
            </Descriptions.Item>
            <Descriptions.Item label="Data Center">
              {procurement_header_data["site_name"]}
            </Descriptions.Item>
            <Descriptions.Item label="Procurement Type">
              {procurement_header_data["procurement_type"]}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </Col>
    </Row>
  );
};

export default ProcurementDetailsHeader;
