import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Input, Row, Space, Table, Tooltip, Typography } from "antd";
import { getUserIdentity } from "helpers/auth";
import moment from "moment";
import React, { useState } from "react";
import useProcurementStore from "store/procurement_store";
import { v4 as uuidv4 } from "uuid";
const { TextArea } = Input;
const {Text} = Typography;

const Notes = () => {
  const [value, setValue] = useState("");
  const { notes, current_stage_data, updateStageData, setNotesAndStageData } =
    useProcurementStore((state) => ({
      notes: state?.notes,
      current_stage_data: state?.current_stage_data,
      updateStageData: state.updateStageData,
      setNotesAndStageData: state.setNotesAndStageData,
    }));

  const handleAddButton = async () => {
    const user_email = (await getUserIdentity()).email;
    const new_note = {
      id: uuidv4(),
      text: value,
      created_by: user_email,
      created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
    };

    let updated_notes_data = [...(notes || []), new_note];

    //update zustand state  by notes: updated_notes_data
    const updated_stage_data = {
      ...current_stage_data, // Keep all previous data
      notes: updated_notes_data, // Update notes array
    };

    setNotesAndStageData(updated_stage_data);
    await updateStageData(updated_stage_data);
  };

  const columns = [
    {
      title: "DESCRIPTION",
      dataIndex: "text",
      key: "text",
      render: (text) => <a>{text}</a>,
      width: 700
    },
    {
      title: "CREATED BY",
      dataIndex: "created_by",
      key: "created_by",
    },
    {
      title: "CREATED AT",
      dataIndex: "created_at",
      key: "created_at",
    },
  
  ];

  const notes_data = notes?.map((note) => ({
    key: notes?.id,
    text:<Text style={{textWrap:'wrap'}}>{note?.text}</Text>,
    created_by: note?.created_by,
    created_at: note?.created_at,
  }));

  return (
    <div>
      <Row align={"middle"} gutter={12} style={{marginBottom:'20px',}} justify={"center"}>
        <Col span={24}>
          <Table columns={columns} dataSource={notes_data} style={{overflowY:"auto", maxHeight:'500px'}}  size="small"/>
        </Col>
      </Row>
      <Row align={"middle"} gutter={12}>
        <Col span={12}>
          <TextArea
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Enter your comments here..."
            autoSize={{ minRows: 2, maxRows: 4 }}
            style={{ borderRadius: "8px", fontSize: "14px" }}
          />
        </Col>
        <Col>
          <Tooltip title="Add note">
            <Button type="primary" size="middle" onClick={handleAddButton}>
              Add
            </Button>
          </Tooltip>
        </Col>
      </Row>
    </div>
  );
};

export default Notes;
