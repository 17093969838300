import { Button, Space } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons'
import MaterialReactTable, { MRT_TableInstance, MaterialReactTableProps } from 'material-react-table'
import { ReactNode, memo, useCallback } from 'react'
import { utils, writeFile } from 'xlsx';

const MaterialReactTableView = memo((props: MaterialReactTableProps<Record<string, any>>) => {
    const renderTopToolbarCustomActions: (props: { table: MRT_TableInstance<Record<string, any>> }) => ReactNode = useCallback(({ table }) => {
        const exportDataToExcel = () => {
            if (props.data.length > 0) {
                const worksheet = utils.json_to_sheet(props.data ? props.data : []);
                const workbook = utils.book_new();
                utils.book_append_sheet(workbook, worksheet, "Sheet1");
                writeFile(workbook, `${new Date().toISOString()}_datasheet.xlsx`);
            }
        };
        return (
            <Space>
                <Button
                    type="default"
                    icon={<FileExcelOutlined style={{ fontSize: "10px" }} />}
                    size={"small"}
                    disabled={props.data.length === 0}
                    onClick={exportDataToExcel}
                >
                    Download Excel
                </Button>
            </Space>
        );
    }, [props.data])
    return <MaterialReactTable
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
        initialState={{ density: 'compact' }}
        enableDensityToggle={false}
        {...props} />
})

export default MaterialReactTableView