import {fetchAuthSession} from '@aws-amplify/auth';
import AWS from 'aws-sdk';

export async function executeLambda(
  lambdaFunctionName: string,
  lambdaBody: any,
  retries: number = 3,
  region: any = process.env.REACT_APP_AWS_DEFAULT_REGION):
  Promise<{
    success: boolean,
    data: any,
    error: null | undefined | Error
  }> {
  let attempt = 0;
  let [result, lambdaResponse]: [any, any] = [null, null]
  // Get AWS credentials using Amplify
  const session = await fetchAuthSession()

  // Configure AWS SDK with obtained credentials
  AWS.config.update({
    credentials: session.credentials,
    region
  });

  // Create an AWS Lambda service object
  const lambda = new AWS.Lambda();

  // Prepare parameters for invoking the lambda function
  const params = {
    FunctionName: lambdaFunctionName,
    Payload: JSON.stringify(lambdaBody)
  };
  while (attempt < retries) {
    try {
      // Invoke the lambda function
      result = await lambda.invoke(params).promise();
      // Parse and return the response
      lambdaResponse = JSON.parse(result.Payload as string);
      if (lambdaResponse?.errorMessage) {
        throw new Error()
      }
      break
    } catch (error) {
      console.error('Error executing lambda function:');
      console.error(error)
      if (lambdaResponse.errorMessage && attempt < retries - 1) {
        console.debug(`Retrying... Attempt ${attempt + 1}/${retries}`);
        attempt++;
        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
      } else {
        return {
          error: Error(`Lambda execution failed. Retries ${attempt + 1}`),
          success: false,
          data: null
        }
      }
    }
  }
  return {
    error: null,
    success: true,
    data: lambdaResponse
  }
}

