import React from "react";
import ProcurementDetailsHeader from "./ProcurementDetailsHeader";
import { ProcurementComms } from "./ProcurementComms";
import { Col, Row, Space } from "antd";

const ProcLayout = ({ children,stage_data }) => {
  return (
    <div>
      <Row gutter={[60, 40]} justify="center">
        <Col span={20}>
          <ProcurementDetailsHeader />
        </Col>
        <Col span={20}>
          <ProcurementComms/>
        </Col>
        <Col span={20}>
        {children}
        </Col>
      </Row>
    </div>
  );
};

export default ProcLayout;
