
import { Col, Collapse, Row, Tabs, Typography } from "antd";
import React from "react";
import Notes from "./CommsPages/Notes";
const {Text} = Typography;
export const ProcurementComms = () => {
  const items = [
    {
      label: <Text style={{fontWeight:450, fontSize:16}}>Notes</Text>,
      key: "1",
      children: <Notes/>,
    },
    {
      label:<Text style={{fontWeight:450,fontSize:16}}>Emails</Text> ,
      key: "2",
      children: "One part will have List of emails, other will have body.",
    },
    {
      label:<Text style={{fontWeight:450,fontSize:16}}>Teams Chat</Text> ,
      key: "3",
      children: "We will see later",
    },
  ];

  const CommsTabs = () => {
    return (
      <Tabs
        defaultActiveKey="1"
        type="card"
        size={"large"}
        style={{
          marginBottom: 32,
        }}
        items={items}
      />
    );
  };

  return (
    <Row gutter={[60, 30]} justify={"center"}>
      <Col span={24}>
        <Collapse
          items={[
            {
              key: "1",
              label: <Text style={{fontWeight:500}}>COMMS</Text>,
              children: <CommsTabs />,
            },
          ]}
        />
      </Col>
    </Row>
  );
};
