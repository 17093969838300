import _, { isNaN } from 'lodash'
import { getUserIdentity } from "helpers/auth"
import moment from 'moment';
import axios from 'axios'; // You may need to install axios if not already installed
import useMasterDataStore from 'store/master_data_store';


export function replacePathsByValue(
  obj,
  replace_keyvals,
  currentPath = "",
  result = []
) {
  let keys_to_replace = Object.keys(replace_keyvals);
  let obj_copy = _.clone(obj);
  recursive(obj, replace_keyvals, currentPath, result);
  function recursive(obj, replace_keyvals, currentPath = "", result = []) {
    if (_.isObject(obj)) {
      _.forEach(obj, (value, key) => {
        const newPath = currentPath ? `${currentPath}.${key}` : key;
        if (_.isString(value) && _.includes(keys_to_replace, value)) {
          _.set(obj_copy, newPath, replace_keyvals[value]);
        } else if (_.isObject(value)) {
          recursive(value, replace_keyvals, newPath, result);
        }
      });
    }
  }
  return obj_copy;
}

export function getQueryParams() {
  const queryParams = {};
  const queryString = window.location.search.slice(1);
  const pairs = queryString.split('&');

  pairs.forEach(pair => {
    const [key, value] = pair.split('=');
    queryParams[decodeURIComponent(key)] = decodeURIComponent(value || '');
  });

  return queryParams;
}

export async function generateDefaultProcurementName(type) {
  const logged_in_user = await getUserIdentity()
  return logged_in_user.isid + '_' + type + '_' + moment.now().toString()
}

export function transformJsonToUiSupportedObject(json) {
  let _json = _.clone(json)
  let version = _.get(_json, 'version_number', null)
  if (version === 2) {
    _json['renderType'] = "stages"
    _json['renderValue'] = {}
    _.get(_json, 'stage_keys', []).forEach((stage) => {
      _json['renderValue'][stage] = { ..._json[stage] }
    })
    return _json
  }

  return _json

}
export function calculatePercentage(number, total, ceil = false) {
  if (typeof number !== 'number' || typeof total !== 'number') {
    throw new Error('Both arguments must be numbers');
  }

  if (total === 0) {
    return 0
  }

  let percentage = (number * total) / 100;

  if (ceil) {
    percentage = Math.ceil(percentage);
  }
  return percentage;
}


// Helper function to convert HTML to plain text
export async function get_html(html_source_url) {
  try {
    // Check if the URL is relative or absolute
    const isRelative = !html_source_url.match(/^(?:\/\/|http[s]?:\/\/)/);


    // If it's relative, prepend it with the base URL of the application
    const sourceUrl = isRelative ? `${window.location.origin}/${html_source_url}` : html_source_url;


    // Fetch HTML content from the URL
    const response = await axios.get(removeDuplicateSlashes(sourceUrl));

    return response.data;
  } catch (error) {
    console.error('Error fetching HTML content:', error);
    return ""; // Return empty string in case of an error
  }
}

export function removeDuplicateSlashes(url) {
  // Split the URL into protocol, domain, path, and query
  const parts = url.split('?');
  const path = parts[0];

  // Replace any occurrence of multiple forward slashes in the path with a single forward slash
  const cleanPath = path.replace(/([^:]\/)\/+/g, '$1');

  // Reconstruct the URL with the cleaned path and query parameters
  return parts.length > 1 ? cleanPath + '?' + parts.slice(1).join('?') : cleanPath;
}

// Helper function to replace variables in a string
export function replace_vars(map, text) {
  // Regular expression to match variables in double curly braces
  const regex = /\{\{([^}]+)\}\}/g;

  // Replace each variable with its corresponding value
  const replacedText = text.replace(regex, (match, p1) => {
    const variableName = p1.trim();
    // Check if the variable exists in the map
    if (map.hasOwnProperty(variableName)) {
      return map[variableName]; // Replace with the corresponding value
    } else {
      return match; // If variable not found, return the original match
    }
  });

  return replacedText;
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const extractEmailsFromString = (str) => {
  if (typeof str === "string") {
    let emails = str.match(/([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    if (Array.isArray(emails)) {
      return emails;
    }
    return [];
  }
  return [];
};

export function isArrayHomogenous(array) {
  if (array.length === 1) {
    return true
  }
  let first_item = Object.prototype.toString.call(array[0]).replaceAll(/[[\]]/g, '').split(' ')[1]
  return Array.prototype.every.call(array, (item) => first_item === Object.prototype.toString.call(item).replaceAll(/[[\]]/g, '').split(' ')[1])
}

export function estimate_cluster_size(est_vcpu_cores, est_ram_gb, est_storage_gb) {
  // Step 1: Filter by CPU cores
  let all_cluster_sizes = useMasterDataStore.getState().getAllClusterSizes()
  let est_cpu_cores = est_vcpu_cores / 4
  let filteredSizes = all_cluster_sizes.filter(size => {
    return size.cpu_cores >= est_cpu_cores;
  });

  // Step 2: Filter by RAM
  filteredSizes = filteredSizes.filter(size => {
    return size.ram_gb >= est_ram_gb;
  });

  // Step 3: Filter by Storage (convert storage_gb to tb)
  let est_storage_tb = est_storage_gb / 1000; // converting storage_gb to tb
  filteredSizes = filteredSizes.filter(size => {
    return size.storage_tb >= est_storage_tb;
  });

  // Step 4: Determine the smallest price in the remaining sizes
  if (filteredSizes.length > 0) {
    filteredSizes.sort((a, b) => a.price - b.price);
    return filteredSizes[0]; // Return the size with the lowest price
  } else {
    return null; // If no sizes match criteria, return null or handle accordingly
  }
}


export function find_closest_match(cores, ram, disks) {
  // Initialize variables to keep track of closest match
  let nodes = useMasterDataStore.getState().getAllClusterSizes()
  let closestNode = null;
  let closestDistance = Number.MAX_VALUE;
  // let distance = Number.MAX_VALUE
  // Iterate over each node in the array
  nodes.forEach(node => {
    // Calculate the "distance" from the node to the given criteria
    // Using a simple Euclidean distance formula for illustration
    let distance = Math.sqrt(
      Math.pow(node.cpu_cores - cores, 2) +
      Math.pow(node.ram_gb - ram, 2) +
      Math.pow(node.num_disk - disks, 2)
    );
    // Check if this node is closer than the current closest match
    if (distance < closestDistance) {
      if (node['cpu_cores'] < cores || node['ram_gb'] < ram || node['num_disk'] < disks) {
        return
      }
      closestDistance = distance;
      closestNode = node;
    }
  });

  return closestNode;
}


export function get_additional_nodes(tshirt_size_info, additional_specs_required) {
  // Extract the specifications from the objects
  const { cpu: nodeCpu, ram: nodeRam, disk: nodeDisk } = tshirt_size_info;
  const { cpu: requiredCpu, ram: requiredRam, disk: requiredDisk } = additional_specs_required;

  // Calculate the number of nodes needed for each resource
  const cpuNodes = Math.ceil(requiredCpu / nodeCpu);
  const ramNodes = Math.ceil(requiredRam / nodeRam);
  const diskNodes = Math.ceil(requiredDisk / nodeDisk);

  // Return the maximum of the three, as we need to cover the maximum requirement among resources
  return Math.round(Math.max(cpuNodes, ramNodes, diskNodes))
}


export const convertAmountToFloat = (value) => {
  let num = value
  if (typeof (value) === 'string') {
    num = parseFloat(value.replace(/[$,]/g, ''));
  }
  if (isNaN(num)) {
    return 0
  }
  return num
}


