import ProcurementIntakeStage from "./ProcurementIntakeStage";
import {
  Col, 
  Typography,
} from "antd";
import useProcurementStore from "store/procurement_store";


const SingleProcurementPageV3 = () => {

  const {procurement_header_data, demandIntakeData,isDataUpdated, setIsDataUpdated} = useProcurementStore((state)=>({
    procurement_header_data: state.procurement_header_data,
    demandIntakeData: state.demandIntakeData,
    isDataUpdated : state.isDataUpdated,
     setIsDataUpdated : state.setIsDataUpdated,
  }))

  const handlePageUpdate = () => {
    let currentvalue = isDataUpdated;
    if (currentvalue === false) {
      setIsDataUpdated(true);
    } else {
      setIsDataUpdated(false);
    }
  };

  return (
    <>
          <Col span={24}>
              <Col span={24}>
                <div
                  id="initial-demand-section"
                  style={{
                    backgroundColor: "white",
                    padding: "40px",
                    border: "3px solid #f6f6f6",
                    borderRadius: "10px",
                  }}
                >
                  <ProcurementIntakeStage
                    demandIntakeData={demandIntakeData}
                    handlePageUpdate={handlePageUpdate}
                    procurementType={procurement_header_data["procurement_type"]}
                  /> 
                </div>
              </Col>
         
          </Col>
    </>
  );
};

export default SingleProcurementPageV3;
