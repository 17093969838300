import {fetchAuthSession} from '@aws-amplify/auth';
import AWS, {DynamoDB} from 'aws-sdk';
import {handleError} from './error_handler';
import moment from "moment/moment";
import {getUserIdentity} from "./auth";

interface Credentials {
  accessKeyId: string;
  secretAccessKey: string;
  region: string;
  sessionToken: string
}

interface DB_RESPONSE {
  success: boolean,
  code: number | string
  data: any,
  error?: Error | null,
  nextStartKey?: any
}


export class DatabaseManager {

  private dynamoDBService: DynamoDB.DocumentClient = new AWS.DynamoDB.DocumentClient();
  private credential_type: 'amplify' | 'manual' = 'amplify'
  private credentials: Credentials | null = null

  constructor(credential_type?: 'amplify' | 'manual', credential_obj?: Credentials) {
    if (credential_type === 'manual' && credential_obj) {
      this.credentials = credential_obj
      this.configureDynamoDBService();
    } else {
      // Initialize DynamoDB service without explicit credentials (use default credentials provider chain)
      this.dynamoDBService = new AWS.DynamoDB.DocumentClient();
    }
  }

  private async configureDynamoDBService(): Promise<any> {
    // Configure DynamoDB service with provided credentials
    if (this.credential_type == 'manual') {
      AWS.config.update({
        accessKeyId: this.credentials?.accessKeyId,
        secretAccessKey: this.credentials?.secretAccessKey,
        region: this.credentials?.region ? this.credentials.region : 'us-east-1',
        sessionToken: this.credentials?.sessionToken
      });
    }
    if (this.credential_type == 'amplify') {
      const session = await fetchAuthSession()
      //@ts-ignore
      AWS.config.update({...session.credentials, region: process.env.REACT_APP_AWS_DEFAULT_REGION})
    }

    // Create DynamoDB service object
    this.dynamoDBService = new AWS.DynamoDB.DocumentClient();
  }

  /**
   * https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/DynamoDB/DocumentClient.html#put-property
   * @param tableName
   * @param item
   */
  public async createObject(
    tableName: string,
    item: any): Promise<DB_RESPONSE> {

    const params: DynamoDB.DocumentClient.Put = {
      TableName: tableName,
      Item: {
        ...item,
        "created_at": moment.utc().toISOString(),
        "created_by": (await getUserIdentity()).email,
      }
    };

    try {
      await this.configureDynamoDBService()
      await this.dynamoDBService.put(params).promise();
      return {
        success: true,
        error: null,
        data: null,
        code: 200
      }
    } catch (error: any) {
      return {
        success: false,
        error: error,
        data: null,
        code: 500
      }
    }
  }

  /**
   * https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/DynamoDB/DocumentClient.html#scan-property
   * @param tableName
   * @param filterExpression
   * @param expressionAttributeNames
   * @param expressionAttributeValues
   * @param projectExpression
   * @param limit
   * @param startKey
   */
  public async getAllObjects(
    tableName: string,
    filterExpression?: string | undefined,
    expressionAttributeNames?: any | undefined,
    expressionAttributeValues?: any | undefined,
    projectExpression?: string | undefined,
    limit?: number | undefined,
    startKey?: any | undefined): Promise<DB_RESPONSE> {

    await this.configureDynamoDBService();
    let params: DynamoDB.DocumentClient.ScanInput = {
      TableName: tableName,
      ProjectionExpression: projectExpression,
      FilterExpression: filterExpression,
      ExpressionAttributeNames: expressionAttributeNames,
      ExpressionAttributeValues: expressionAttributeValues,
      Limit: limit,
      ExclusiveStartKey: startKey
    };

    try {
      let scanData: any[] = []
      do {
        const response = await this.dynamoDBService.scan(params).promise();

        if (response.Items) {
          response.Items.forEach(item => {
            scanData.push(item);
          });
        }

        params = {
          ...params,
          ExclusiveStartKey: response.LastEvaluatedKey
        }
      } while (params.ExclusiveStartKey);

      return {
        success: true,
        code: 200,
        data: scanData,
      }
    } catch (error: any) {
      handleError(error);
      return {
        success: false,
        code: 500,
        error: error,
        data: []
      }
    }
  }

  /**
   * See https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/DynamoDB/DocumentClient.html#get-property
   * @param tableName
   * @param key
   * @param projectionExpression
   */
  public async getObjectById(
    tableName: string,
    key: any,
    projectionExpression?: string | undefined): Promise<DB_RESPONSE> {
    await this.configureDynamoDBService()
    const params: DynamoDB.DocumentClient.GetItemInput = {
      TableName: tableName,
      ProjectionExpression: projectionExpression,
      Key: key
    };

    try {
      const data = await this.dynamoDBService.get(params).promise();
      if (!data.Item) {
        return {
          data: null,
          error: new Error(`RECORD_NOT_FOUND`),
          success: false,
          code: 401
        }
      }
      return {
        data: data.Item,
        success: true,
        error: null,
        code: 200
      }
    } catch (error: any) {
      return {
        success: false,
        error,
        data: null,
        code: 500
      }
    }
  }

  /**
   * See https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/DynamoDB/DocumentClient.html#query-property
   * @param tableName
   * @param keyConditionExpression
   * @param filterExpression
   * @param expressionAttributeValues
   * @param projectionExpression
   */
  public async queryTable(
    tableName: string,
    keyConditionExpression?: string | undefined,
    filterExpression?: string | undefined,
    expressionAttributeValues?: any | undefined,
    projectionExpression?: string | undefined): Promise<DB_RESPONSE> {

    await this.configureDynamoDBService()
    if (tableName == undefined) {
      return {
        data: null,
        success: false,
        error: new Error('tableName is undefined'),
        code: 500
      }
    }

    const params: DynamoDB.DocumentClient.QueryInput = {
      TableName: tableName,
      ProjectionExpression: projectionExpression,
      KeyConditionExpression: keyConditionExpression,
      FilterExpression: filterExpression,
      ExpressionAttributeValues: expressionAttributeValues
    };

    try {
      const data = await this.dynamoDBService.query(params).promise();
      if (!data.Items) {
        return {
          data: null,
          error: new Error(`RECORD_NOT_FOUND`),
          success: false,
          code: 401
        }
      }
      return {
        data: data.Items,
        success: true,
        error: null,
        code: 200
      }
    } catch (error: any) {
      return {
        success: false,
        error,
        data: null,
        code: 500
      }
    }
  }

  public async updateObject(
    tableName: string,
    item: any): Promise<DB_RESPONSE> {

    const params: DynamoDB.DocumentClient.Put = {
      TableName: tableName,
      Item: {
        ...item,
        "updated_at": moment.utc().toISOString(),
        "updated_by": (await getUserIdentity()).email,
      }
    };

    try {
      await this.configureDynamoDBService()
      await this.dynamoDBService.put(params).promise();
      return {
        success: true,
        error: null,
        data: null,
        code: 200
      }
    } catch (error: any) {
      return {
        success: false,
        error: error,
        data: null,
        code: 500
      }
    }
  }

  /**
   * https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/DynamoDB/DocumentClient.html#update-property
   * @param tableName
   * @param key
   * @param updateExpression
   * @param conditionExpression
   * @param expressionAttributeNames
   * @param expressionAttributeValues
   */
  public async updateRecord(
    tableName: string,
    key: any,
    updateExpression: string,
    conditionExpression?: string | undefined,
    expressionAttributeNames?: any | undefined,
    expressionAttributeValues?: any | undefined): Promise<DB_RESPONSE> {

    const params: DynamoDB.DocumentClient.UpdateItemInput = {
      TableName: tableName,
      Key: key,
      UpdateExpression: updateExpression,
      ConditionExpression: conditionExpression,
      ExpressionAttributeNames: expressionAttributeNames,
      ExpressionAttributeValues: expressionAttributeValues,
      ReturnValues: 'UPDATED_NEW'
    };

    try {
      await this.configureDynamoDBService()
      const response = await this.dynamoDBService.update(params).promise();
      return {
        success: true,
        error: null,
        data: response.Attributes,
        code: 200
      }
    } catch (error: any) {
      return {
        success: false,
        error: error,
        data: null,
        code: 500
      }
    }
  }

  /**
   * https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/DynamoDB/DocumentClient.html#delete-property
   * @param tableName
   * @param keys
   * @param conditionalExpression
   * @param expressionAttributeValues
   */
  public async deleteObject(
    tableName: string,
    keys: any,
    conditionalExpression?: any | undefined,
    expressionAttributeValues?: any | undefined): Promise<void> {
    const params: DynamoDB.DocumentClient.Delete = {
      TableName: tableName,
      Key: keys,
      ConditionExpression: conditionalExpression,
      ExpressionAttributeValues: expressionAttributeValues
    };

    try {
      await this.dynamoDBService.delete(params).promise();
    } catch (error: any) {
      handleError(error)
    }
  }

  public getDynamoDBService = () => {
    return this.dynamoDBService
  }
}


export function getUpdateParamsFromPath(path: string, value: any) {
  let pathSegments = path.split('.')
  let _pathSegments = pathSegments.map((seg) => '#' + seg.replaceAll(/[ \/]/g, '_'))
  let result: any = {
    UpdateExpression: '',
    ExpressionAttributeNames: {},
    ExpressionAttributeValues: {}
  }

  pathSegments.forEach((seg, idx) => {
    result.ExpressionAttributeNames[_pathSegments[idx]] = seg
  })
  result.UpdateExpression = 'SET ' + _pathSegments.join('.') + ' = :value'
  result.ExpressionAttributeValues = {':value': value}
  return result

}
