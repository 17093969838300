import { notification, Row } from "antd";

import SingleProcurementPageV3 from "../SingleProcurementPageV3";

const DataSection = () => { 
  return (
    <Row>
       <SingleProcurementPageV3 />
    </Row>
  )
};

export default DataSection;
