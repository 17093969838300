import {APIResponse} from "./types";
import _ from "lodash";
import {DatabaseManager} from "helpers/db";
import {handleError} from "helpers/error_handler";
import {executeLambda} from "helpers/lambda";
import version_map from "../config/version_map";
import {
  ENTITY_TYPES,
  PROCUREMENT_STAGES,
  PROCUREMENT_STAGES_KEY,
  PROCUREMENT_STATUS,
  PROCUREMENT_TYPES,
  SK_PROC_STAGE_PREFIX
} from "../types";
import moment from "moment";
import {getUserIdentity} from "../helpers/auth";

const dbManager = new DatabaseManager()

/**
 * @deprecated NOT to be used
 * @param formData
 */
const initializeProcurementApi: (
  formData: {
    sizingInfo: any,
    procurement_name: string,
    procurement_type: string,
    estimated_tshirt_size: string,
    cluster_name: string,
    estimated_price: number,
    additional_nodes: number,
    PK: string,
    SK: string
  }
) => Promise<APIResponse> = async (formData) => {
  try {
    //TODO:
    let procurement_info = {
      "PK": formData.PK,
      "SK": formData.SK,
      "entity_type": ENTITY_TYPES.Procurement,
      "procurement_name": formData.procurement_name,
      "procurement_type": formData.procurement_type,
      "procurement_status": PROCUREMENT_STATUS.Initial
    }

    let stage_sizing = {
      "PK": formData.PK,
      "SK": SK_PROC_STAGE_PREFIX + PROCUREMENT_STAGES.Sizing,
      "entity_type": ENTITY_TYPES.ProcurementStage,
      "stage_name": PROCUREMENT_STAGES.Sizing,
      "stage_status": PROCUREMENT_STATUS.Initial,

      "region": "",
      "country": "",
      "site_code": "",
      "site_name": "",
      "cluster_name": formData.cluster_name,
      "cluster_info": {},
      "expected_growth_rate": formData.sizingInfo.expected_growth_rate,
      "existing": formData.sizingInfo.existing,
      "buffer": formData.sizingInfo.buffer,
      "subtotal": formData.sizingInfo.subtotal,
      "grand_total": formData.sizingInfo.grand_total,
      "additional_nodes": formData.additional_nodes,

      "estimated_tshirt_size": formData.estimated_tshirt_size,
      "estimated_price": formData.estimated_price
    }

    if (formData.procurement_type === PROCUREMENT_TYPES.NEW) {
      let stage_sizing_new_proc = {
        "projection_year_1": formData.sizingInfo.projection_year_1,
        "projection_year_2": formData.sizingInfo.projection_year_2,
        "projection_year_3": formData.sizingInfo.projection_year_3
      }
      _.merge(stage_sizing, stage_sizing_new_proc)

    }
    // else if (formData.procurement_type === PROCUREMENT_TYPES.EXPANSION) {
    //   let stage_intake_expansion_proc = {
    //     "additional_req": formData.sizingInfo.additional_req
    //   }
    //   _.merge(stage_intake, stage_intake_expansion_proc)
    // }


    //TODO: Convert to BatchWrite
    await dbManager.createObject(process.env.REACT_APP_DYNAMO_DB_PROCUREMENTS || "", procurement_info)
    await dbManager.createObject(process.env.REACT_APP_DYNAMO_DB_PROCUREMENTS || "", stage_sizing)

    return {
      success: true,
      data: {},
      error: null,
      code: ''
    }
  } catch (e: any) {
    handleError(e)
    return {
      success: false,
      code: '500',
      data: null,
      error: e
    }
  }
}

/**
 * Get Procurement details for given PK
 * @param PK
 * @param stageName
 */
const getProcurementDetails: (
  PK: string,
  stageName?: string | undefined
) => Promise<APIResponse> =
  async (PK, stageName?: string | undefined) => {
    try {
      let keyConditionExpression = 'PK = :pk_value'
      let expressionAttributeValues = {}

      if (stageName) {
        keyConditionExpression += ' AND SK = :sk_value';
        expressionAttributeValues = {
          ':pk_value': PK,
         ':sk_value': SK_PROC_STAGE_PREFIX + stageName
        }
      } else {
        expressionAttributeValues = {
          ':pk_value': PK
        }
      }

      let response = await dbManager.queryTable(
        process.env.REACT_APP_DYNAMO_DB_PROCUREMENTS || "",
        keyConditionExpression,
        undefined,
        expressionAttributeValues
      )

      return {
        success: true,
        data: response.data,
        error: null,
        code: 200
      }
    } catch (e: any) {
      handleError(e)
      return {
        success: false,
        code: '500',
        data: null,
        error: e
      }
    }
  }

/**
 * Get All Procurement Stage Items for
 */
const getAllProcurementStages: () => Promise<APIResponse> =
  async () => {
    try {
      //FIXME: Adding default filters for validity of the Dashboard data.
      let filterExpression = 'entity_type = :entity_type_value ' +
        'and attribute_exists(started_at) ' +
        'and attribute_exists(completed_at) ' +
        'and not (started_at in (:date_val1)) ' +
        'and not (completed_at in (:date_val1)) '
      let expressionAttributeValues = {
        ':entity_type_value': ENTITY_TYPES.ProcurementStage,
        ':date_val1': ""
      }
      let response = await dbManager.getAllObjects(
        process.env.REACT_APP_DYNAMO_DB_PROCUREMENTS || "",
        filterExpression,
        undefined,
        expressionAttributeValues,
        "PK, stage_name, started_at, completed_at"
      )

      return {
        success: true,
        data: response.data,
        error: null,
        code: 200
      }
    } catch (e: any) {
      handleError(e)
      return {
        success: false,
        code: '500',
        data: null,
        error: e
      }
    }
  }


/**
 * Updates the Procurement Stage Item
 * @param stageItem
 */
const updateProcurementStageItem: (
  stageItem: any
) => Promise<APIResponse> =
  async (stageItem) => {
    try {
      if (stageItem['stage_status'] === PROCUREMENT_STATUS.Submitted) {
        if (stageItem['started_at']) {
          stageItem['started_at'] = moment.utc().toISOString()
          stageItem['started_by'] = (await getUserIdentity()).email
        }
      } else if (stageItem['stage_status'] === PROCUREMENT_STATUS.Approved) {
        stageItem['completed_at'] = moment.utc().toISOString()
        stageItem['completed_by'] = (await getUserIdentity()).email
      }
      let response = await dbManager.updateObject(
        process.env.REACT_APP_DYNAMO_DB_PROCUREMENTS || "",
        stageItem
      )
      return {
        success: true,
        data: {},
        code: '',
        error: ''
      }

    } catch (e: any) {
      handleError(e)
      return {
        success: false,
        code: '500',
        data: null,
        error: e
      }
    }
  }

const updateSizingInfoAPI: (
  PK: string,
  SK: string,
  sizingInfo: {
    [key: string]: any
  },
  version_number: number
) => Promise<APIResponse> =
  async (PK, SK, sizingInfo, version_number) => {
    try {
      let key = {PK: PK, SK: SK}
      let pathSegments = version_map[4]['SIZING_INFO_PATH'].split('.')
      let _pathSegments = pathSegments.map((seg) => '#' + seg.replaceAll(/[ /]/g, '_'))
      let updateExpression = ''
      let expressionAttributeNames = {}
      let expressionAttributeValues = {}

      pathSegments.forEach((seg, idx) => {
        // @ts-ignore
        expressionAttributeNames[_pathSegments[idx]] = seg
      })
      updateExpression = 'SET ' + _pathSegments.join('.') + ' = :value'
      expressionAttributeValues = {':value': sizingInfo}

      //TODO: Needs to be fixed
      let result = await dbManager.updateRecord(
        process.env.REACT_APP_DYNAMO_DB_PROCUREMENTS || "",
        key,
        updateExpression,
        undefined,
        expressionAttributeNames,
        expressionAttributeValues
      )
      return {
        success: true,
        data: {},
        code: '',
        error: ''
      }

    } catch (e: any) {
      handleError(e)
      return {
        success: false,
        code: '500',
        data: null,
        error: e
      }
    }
  }

//TODO: Not used?
// const determinedSizingInfoAPI: (
//   formData: {
//     PK: string,
//     SK: string,
//     tshirt_size: any
//   },
//   version_number: number,
//   operation_type: string
// ) => Promise<APIResponse> =
//   async (formData,
//          version_number,
//          operation_type = 'UPDATE') => {
//     try {
//       if (operation_type === 'UPDATE') {
//         let result = await dbManager.updateRecord(
//           process.env.REACT_APP_DYNAMO_DB_PROCUREMENTS || "",
//           'PK',
//           formData['Id'],
//           version_map[version_number]['DETERMINED_SIZING_INFO_PATH'],
//           {tshirt_size: formData.tshirt_size})
//         return {
//           success: result.success,
//           data: result.data,
//           code: '',
//           error: result.error
//         }
//       }
//       throw new Error("Unsupported operation_type provided in the react app")
//     } catch (e: any) {
//       handleError(e)
//       return {
//         success: false,
//         code: '500',
//         data: null,
//         error: e
//       }
//     }
//   }

const tshirtSizeInfoAPI: (cluster_name: string, show_error_notification: boolean) => Promise<APIResponse> = async (cluster_name, show_error_notification = true) => {

  try {
    //@ts-ignore
    let result = await executeLambda(process.env.REACT_APP_LAMBDA_TSHIRT_SIZE || "", {cluster_name})
    if (!result.success) {
      throw new Error(result.error?.message)
    }

    return {
      success: true,
      data: result.data.data,
      error: null,
      code: ''
    }
  } catch (e: any) {
    if (show_error_notification) handleError(e)
    return {
      success: false,
      code: '500',
      data: null,
      error: e
    }
  }
}

const sendEmailAPI: (
  type: 'initialize_procurement' | 'custom',
  email_params: {
    html: string,
    subject: string,
    to: string[],
    cc: string[],
    bcc: string[],
    replymail: string[]
  }
) => Promise<APIResponse> =
  async (type, email_params) => {
    try {
      let recipients_type = ['to', 'cc', 'bcc', 'replymail']
      let partial_email_params: any = {}
      recipients_type.forEach((rec) => {
        let param: string[] | boolean = _.get(email_params, rec, false)
        if (param && !_.isArray(param)) {
          throw new Error(`Parameter ${rec} should be an array`)
        }
        //@ts-ignore
        if (param && param?.length > 0) {
          //@ts-ignore
          partial_email_params[rec] = param.join(',')
        }
      })
      if (process.env.REACT_APP_SEND_EMAILS === 'true') {
        let result = await executeLambda(process.env.REACT_APP_LAMBDA_EMAIL || "", {
          body: {
            html: email_params['html'],
            subject: email_params['subject'],
            ...partial_email_params
          }
        })
        if (result.success && result.data?.['statusCode'] !== 204) {
          return {
            success: false,
            error: 'Error occured',
            data: null,
            code: '500'
          }
        }
      } else {
        console.debug('Not sending email. Data: ')
        console.debug(email_params)
      }
      return {
        success: true,
        error: null,
        data: null,
        code: '201'
      }

    } catch (e) {
      return {
        success: false,
        error: e,
        data: null,
        code: '500'
      }
    }
  }
const clusterInfoPrismAPI: (cluster_name: string) => Promise<APIResponse> = async (cluster_name) => {
  try {
    if (_.isEmpty(cluster_name)) {
      throw new Error('Cluster name cannot be empty')
    }
    let response = await executeLambda(process.env.REACT_APP_LAMBDA_PRISM_DATA_API || "", {cluster_name})
    if (response.success && response.data) {
      return {
        success: true,
        error: null,
        data: response.data,
        code: 200
      }
    }

    return {
      success: false,
      error: null,
      data: null,
      code: 500
    }
  } catch (e) {
    return {
      success: false,
      error: e,
      data: null,
      code: 500
    }
  }
}

/**
 * Calls lambda - prism-v3-new-version
 * @param cluster_name
 * @param region
 */
const getClusterInfo: (
  cluster_name: string, region: string
) => Promise<APIResponse> =
  async (cluster_name, region) => {
    try {
      if (_.isEmpty(cluster_name)) {
        throw new Error('Cluster name cannot be empty')
      }
      let body: any = {cluster_name}
      if (region) {
        body['region'] = region
      }
      let response = await executeLambda(process.env.REACT_APP_LAMBDA_PRISM_CLUSTER_INFO_API || "", body)

      if (response.success && response.data) {
        if (response.data?.statusCode !== 200) {
          return {
            success: false,
            error: JSON.parse(response.data.body),
            data: null,
            code: response.data.statusCode
          }
        }

        return {
          success: true,
          error: null,
          data: response.data.body,   //JSON.parse(response.data.body),
          code: 200
        }
      }

      return {
        success: false,
        error: null,
        data: null,
        code: 500
      }
    } catch (e) {
      return {
        success: false,
        error: e,
        data: null,
        code: 500
      }
    }
  }

const clusterNamesAPI: (
  search_q?: string
) => Promise<APIResponse> =
  async (search_q = '') => {

    try {
      let result = await dbManager.getAllObjects(process.env.REACT_APP_DYNAMO_DB_CLUSTER_NAMES_TABLE_NAME || "")
      if (!result.success) {
        throw result.error
      }
      return {
        success: true,
        data: result.data,
        error: null,
        code: ''
      }
    } catch (e: any) {
      return {
        success: false,
        code: '500',
        data: null,
        error: e
      }
    }
  }


const clusterSizesAPI: () => Promise<APIResponse> = async () => {

  try {
    //@ts-ignore
    let result = await dbManager.getAllObjects(
      process.env.REACT_APP_DYNAMO_DB_CLUSTER_SIZES_TABLE_NAME || "")
    if (!result.success) {
      throw result.error
    }
    return {
      success: true,
      data: result.data,
      error: null,
      code: ''
    }
  } catch (e: any) {
    return {
      success: false,
      code: '500',
      data: null,
      error: e
    }
  }
}

/**
 * Get T-Shirt Sizing for New Cluster
 * @param total_vcpu
 * @param total_ram
 * @param total_storage
 * @param total_nics
 */
const getTShirtSizingForNewCluster: (
  total_vcpu: string,
  total_ram: string,
  total_storage: string,
  total_nics?: string
) => Promise<APIResponse> =
  async (total_vcpu, total_ram, total_storage, total_nics) => {
    try {

      let in_body = {
        'vCPU': total_vcpu,
        'RAM': total_ram,
        'STORAGE': total_storage
      }

      let response = await executeLambda(
        process.env.REACT_APP_LAMBDA_TSHIRT_SIZING_NEW_CLUSTER || "",
        in_body)
      //response format
      // {
      //   'Tshirt_size': "3 NODE",
      //   'price': 1234.56
      // }

      if (response.success && response.data) {
        if (response.data?.statusCode !== 200) {
          return {
            success: false,
            error: JSON.parse(response.data.body),
            data: null,
            code: response.data.statusCode
          }
        }

        return {
          success: true,
          error: null,
          data: response.data,
          code: 200
        }
      }

      return {
        success: false,
        error: null,
        data: null,
        code: 500
      }
    } catch (e) {
      return {
        success: false,
        error: e,
        data: null,
        code: 500
      }
    }
  }

/**
 * T-Shirt Sizing for Expansion Cluster
 * @param tshirt_size_value
 * @param total_vcpu
 * @param total_ram
 * @param total_storage
 * @param total_nics
 */
const getTShirtSizingForExpansionCluster: (
  tshirt_size_value: string,
  total_vcpu: string,
  total_ram: string,
  total_storage: string,
  total_nics?: string
) => Promise<APIResponse> =
  async (tshirt_size_value, total_vcpu, total_ram, total_storage, total_nics) => {
    try {

      let in_body = {
        'tshirt_size_value': tshirt_size_value,
        'vCPU': total_vcpu,
        'RAM': total_ram,
        'STORAGE': total_storage
      }

      let response = await executeLambda(
        process.env.REACT_APP_LAMBDA_TSHIRT_SIZING_EXPANSION_CLUSTER || "",
        in_body)

      if (response.success && response.data) {
        if (response.data?.statusCode !== 200) {
          return {
            success: false,
            error: JSON.parse(response.data.body),
            data: null,
            code: response.data.statusCode
          }
        }

        return {
          success: true,
          error: null,
          data: response.data.body,
          code: 200
        }
      }

      return {
        success: false,
        error: null,
        data: null,
        code: 500
      }
    } catch (e) {
      return {
        success: false,
        error: e,
        data: null,
        code: 500
      }
    }
  }

/**
 * Get T-Shirt Sizes values for dropdown, based on procurement_type
 * @param procurement_type
 */
const getAllTShirtSizes: (
  procurement_type: string
) => Promise<APIResponse> =
  async (procurement_type) => {
    try {
      let filterExpression = undefined
      if (procurement_type === PROCUREMENT_TYPES.NEW) {
        filterExpression = "NOT begins_with(#Tshirt_size, :prefix)"
      } else if (procurement_type === PROCUREMENT_TYPES.EXPANSION) {
        filterExpression = "begins_with(#Tshirt_size, :prefix)"
      }
      let expressionAttributeName = {
        "#Tshirt_size": "Tshirt_size"
      }
      let expressionAttributeValues = {
        ':prefix': "EXP "
      }
      let response = await dbManager.getAllObjects(
        process.env.REACT_APP_DYNAMO_DB_TSHIRT_SIZING || "",
        filterExpression,
        expressionAttributeName,
        expressionAttributeValues,
        "Tshirt_size"
      )

      let tshirt_sizes = response.data.map((data_item: { Tshirt_size: string }) => {
        if (data_item.Tshirt_size.startsWith("EXP ")) {
          data_item.Tshirt_size = data_item.Tshirt_size.slice("EXP ".length).slice(0, -" Gen-11".length);
        }
        // if (procurement_type === PROCUREMENT_TYPES.NEW &&  data_item.Tshirt_size.endsWith(" Gen-11")) {
        //     data_item.Tshirt_size =  data_item.Tshirt_size.slice(0, -" Gen-11".length);
        // }
        return data_item
      })
      return {
        success: true,
        data: tshirt_sizes,
        error: null,
        code: 200
      }
    } catch (e: any) {
      handleError(e)
      return {
        success: false,
        code: '500',
        data: null,
        error: e
      }
    }
  }


const deleteProcurement: (
  PK: string
) => Promise<APIResponse> =
  async (PK) => {
    try {
      // Get all the items related to procurement for given PK
      let keyConditionExpression = 'PK = :pk_value'
      let expressionAttributeValues = {
        ':pk_value': PK
      }
      let result_procurement_items = await dbManager.queryTable(
        process.env.REACT_APP_DYNAMO_DB_PROCUREMENTS || "",
        keyConditionExpression,
        undefined,
        expressionAttributeValues,
        "PK, SK"
      )

      if (result_procurement_items.success) {
        // Delete all items (Procurement & Stages) for given procurement
        result_procurement_items.data.map(async (procurement_item: any, index: number) => {
            let procurement_item_key = {
              PK: PK,
              SK: procurement_item['SK']
            }
            let result = await dbManager.deleteObject(
              process.env.REACT_APP_DYNAMO_DB_PROCUREMENTS || "",
              procurement_item_key
            )
          }
        )
      }

      return {
        success: true,
        data: {},
        code: '',
        error: ''
      }

    } catch (e: any) {
      handleError(e)
      return {
        success: false,
        code: '500',
        data: null,
        error: e
      }
    }
  }

export {
  initializeProcurementApi,
  getProcurementDetails,
  getAllProcurementStages,
  updateProcurementStageItem,
  updateSizingInfoAPI,
  tshirtSizeInfoAPI,
  sendEmailAPI,
  clusterInfoPrismAPI,
  clusterNamesAPI,
  clusterSizesAPI,
  getClusterInfo,
  getTShirtSizingForNewCluster,
  getTShirtSizingForExpansionCluster,
  getAllTShirtSizes,
  deleteProcurement
}