import { notification } from "antd"
import { AWSError } from "aws-sdk"
import ErrorDescription from "./ErrorDescription"
import React from "react"

const handleError = (error: Error | AWSError) => {
    let short_description = "Error Occured"
    let detailed_description = error.message
    if (error.name === "AccessDeniedException") {
        short_description = 'You are not allowed to perform this action'
    }

    notification.error({
        message: error.name,
        description: React.createElement(ErrorDescription,
            {
                short_description,
                detailed_description
            }),
        duration: 0,
    })




}


export { handleError }